<template>
  <div>
    <div class="uk-grid">
      <div class="uk-with-1-3@m uk-hidden@m uk-margin-medium-bottom">
        <h4>Extend membership by {{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</h4>
        <hr>
      </div>
      <div class="uk-with-1-3@m uk-visible@m" style="border-right: solid 1px #ddd">
        <table class="uk-table uk-table-divider">
          <tbody>
            <tr>
              <td>Date Joined</td>
              <td>{{ membership.joined }}</td>
            </tr>
            <tr>
              <td>Membership Expiry</td>
              <td>{{ membership.expiry }}</td>
            </tr>
            <tr>
              <td>Selected Plan</td>
              <td>{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>$ {{ plan.amount }}</td>
            </tr>
            <tr v-if="plan.discount != null">
              <td>Discount</td>
              <td>{{ plan.discount }} % Applied</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="uk-width-2-3@m">
        <payment
          class="uk-padding-large-left"
          type="community"
          :id="$route.params.id"
          @received="paymentReceived"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      membership: {},
      plan: {}
    }
  },

  methods: {
    fetch() {
      this.$axios.get(`/api/v1/trader/my-account/membership/extend/${this.$route.params.id}`).then(response => {
        this.membership = response.data.membership
        this.plan = response.data.plan
      })
    },

    paymentReceived() {
      window.location.href = this.$router.resolve({ name: 'trader.my-account.membership' }).href
    }
  }
}
</script>